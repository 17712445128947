<template>
  <table-container :list="list">
    <el-table-column type="index" label="序号" width="40" fixed />
    <el-table-column prop="name" label="代理name" fixed />
    <el-table-column prop="category_name" label="代理类型" width="100" />
    <el-table-column prop="level_name" label="代理级别" width="80" />
    <el-table-column prop="balance" label="余额" width="80" />
    <el-table-column prop="default_express_name" label="默认快递" />
    <el-table-column prop="contact_person" label="联系人" />
    <el-table-column prop="contact_number" label="联系方式" />
    <el-table-column prop="inner_password" label="内部密码" />
    <el-table-column prop="can_handle_order" label="手工单权限" width="90">
      <template slot-scope="scope">
        <span v-if="scope.row.can_handle_order === 1">是</span>
        <span v-else>否</span>
      </template>
    </el-table-column>
    <el-table-column prop="desc" label="备注" width="100" />
    <el-table-column prop="shopnames" label="门店列表" />
    <el-table-column label="代理产品" width="90">
      <template slot-scope="scope">
        <el-button
          type="text"
          size="mini"
          icon="el-icon-edit"
          @click="handleProdCheck(scope.row)"
          >查看</el-button
        >
      </template>
    </el-table-column>
    <el-table-column label="快递价格" width="90">
      <template slot-scope="scope">
        <el-button
          type="text"
          size="mini"
          icon="el-icon-edit"
          @click="handleExpress(scope.row)"
          >查看</el-button
        >
      </template>
    </el-table-column>
    <el-table-column prop="created_at" label="创建日期" width="150" />
    <el-table-column prop="updated_at" label="修改日期" width="150" />
    <el-table-column label="操作" fixed="right" width="180">
      <template slot-scope="scope">
        <el-button type="primary" size="mini" @click="handleEdit(scope.row)"
          >编辑</el-button
        >
        <delete-btn :item="scope.row" @del="handleDelete"></delete-btn>
        <el-button type="success" size="mini" @click="handleRecharge(scope.row)"
          >充值</el-button
        >
      </template>
    </el-table-column>
  </table-container>
</template>
<script>
export default {
  name: "List",
  props: {
    list: Array,
  },
  methods: {
    //编辑
    handleEdit(item) {
      this.$emit("edit", item);
    },
    //充值
    handleRecharge(item) {
      this.$emit("recharge", item);
    },
    //查看产品
    handleProdCheck(item) {
      this.$emit("prod", item);
    },
    //查看快递
    handleExpress(item) {
      this.$emit("express", item);
    },
    async handleDelete(item) {
      const res = await this.$store.dispatch("delAgent", {
        agent_id: item.id,
      });
      this.$tool.comfirmToast(res).then(() => {
        this.$emit("succ");
      });
    },
  },
};
</script>
<style lang="less" scoped>
</style>