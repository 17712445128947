<template>
  <ky-dialog :title="title" :show="show" :hide="hide">
    <el-input
      slot="dialog-content"
      v-model="amount"
      placeholder="输入充值金额"
    ></el-input>
    <span slot="dialog-footer" class="dialog-footer">
      <el-button @click="handleCancel">取 消</el-button>
      <el-button type="primary" @click="handleComfirm">确 定</el-button>
    </span>
  </ky-dialog>
</template>
<script>
export default {
  name: "AgentRecharge",
  props: {
    show: Boolean,
    id: [String, Number],
  },
  data() {
    return {
      hide: false,
      title: "代理充值",
      labelPosition: "right",
      amount: null,
    };
  },
  methods: {
    //取消
    handleCancel() {
      this.hide = !this.hide;
    },
    //确定
    async handleComfirm() {
      if (this.amount <= 0) {
        this.$message({
          message: "金额无效",
          type: "error",
        });
        return;
      }
      const res = await this.$store.dispatch("agentRecharge", {
        agent_id: this.id,
        amount: this.amount,
      });
      this.$tool.comfirmToast(res).then(() => {
        this.$emit("succ");
        this.hide = !this.hide;
      });
    },
  },
};
</script>
<style lang="less" scoped>
.dialog-footer {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}
</style>