<template>
  <ky-dialog :title="title" :show="show" :hide="hide">
    <el-form
      slot="dialog-content"
      :model="formData"
      :rules="rules"
      ref="formData"
      label-width="90px"
    >
      <el-row :gutter="10">
        <el-col :span="11">
          <el-form-item label="代理名称:" prop="name">
            <el-input
              v-model="formData.name"
              autocomplete="off"
              placeholder="请输入代理名称"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item label="默认快递:" prop="default_express_id">
            <el-select
              filterable
              v-model="formData.default_express_id"
              placeholder="选择快递"
            >
              <el-option
                v-for="item in expressList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="11"
          ><el-form-item filterable label="代理类型:" prop="agent_cate_id">
            <el-select
              v-model="formData.agent_cate_id"
              placeholder="选择代理类型"
            >
              <el-option
                v-for="item in typeList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select> </el-form-item
        ></el-col>
        <el-col :span="11"
          ><el-form-item label="代理等级:" prop="level_id">
            <el-select
              filterable
              v-model="formData.level_id"
              placeholder="选择代理等级"
            >
              <el-option
                v-for="item in levelList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select> </el-form-item
        ></el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="11">
          <el-form-item label="联系人:" prop="contact_person">
            <el-input
              v-model="formData.contact_person"
              autocomplete="off"
              placeholder="请输入联系人"
            /> </el-form-item
        ></el-col>
        <el-col :span="11"
          ><el-form-item label="联系方式:" prop="contact_number">
            <el-input
              v-model="formData.contact_number"
              autocomplete="off"
              placeholder="请输入联系方式"
            /> </el-form-item
        ></el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="11">
          <el-form-item label="密码:" prop="password">
            <el-input
              v-model.trim="formData.password"
              autocomplete="off"
              :placeholder="passwordPlaceholder"
            /> </el-form-item
        ></el-col>
        <el-col :span="11">
          <el-form-item label="内部密码:" prop="inner_password">
            <el-input
              v-model="formData.inner_password"
              autocomplete="off"
              placeholder="请输入内部密码"
            /> </el-form-item
        ></el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="11">
          <el-form-item label="简码:" prop="code">
            <el-input
              v-model="formData.code"
              autocomplete="off"
              placeholder="请输入简码"
            /> </el-form-item
        ></el-col>
        <el-col :span="11">
          <el-form-item label="手工单权限:" prop="can_handle_order">
            <el-select
              filterable
              v-model="formData.can_handle_order"
              placeholder="选择创建订单权限"
            >
              <el-option
                v-for="item in CanHandleOrderList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select> </el-form-item
        ></el-col>
      </el-row>

      <el-form-item label="门店列表:">
        <el-input v-model="formData.shopnames" placeholder="多个用分号;分开" />
      </el-form-item>
      <el-form-item label="排序:">
        <el-input v-model="formData.sort" autocomplete="off" />
      </el-form-item>
      <el-form-item label="备注:">
        <el-input v-model="formData.desc" autocomplete="off" />
      </el-form-item>
    </el-form>
    <span slot="dialog-footer" class="dialog-footer">
      <el-button @click="handleCancel">取 消</el-button>
      <el-button type="primary" @click="handleComfirm">确 定</el-button>
    </span>
  </ky-dialog>
</template>
<script>
export default {
  name: "EditAgent",
  props: {
    expressList: Array,
    levelList: Array,
    typeList: Array,
    type: String,
    show: Boolean,
    item: Object,
  },
  data() {
    return {
      hide: false,
      title: "新增代理",
      passwordPlaceholder: "不少于八位数",
      labelPosition: "right",
      CanHandleOrderList: [
        {
          value: 1,
          label: "是",
        },
        {
          value: 0,
          label: "否",
        },
      ],
      formData: {
        name: "",
        default_express_id: "",
        level_id: "",
        agent_cate_id: "",
        desc: "",
        sort: 1,
        password: "",
        code: "",
        contact_person: "",
        contact_number: "",
        inner_password: "",
        can_handle_order: 1,
        shopnames: "",
      },
      rules: {
        name: [{ required: true, message: "请输入代理名称", trigger: "blur" }],
        default_express_id: [
          { required: true, message: "快递不能为空", trigger: "blur" },
        ],
        level_id: [
          { required: true, message: "代理级别不能为空", trigger: "blur" },
        ],
        agent_cate_id: [
          {
            required: true,
            message: "代理类型不能为空",
            trigger: "blur",
          },
        ],
        password: [
          // {
          //   required: true,
          //   message: "请输入密码",
          //   trigger: "blur",
          // },
          {
            min: 8,
            max: 13,
            message: "长度在 8 到 13 个字符",
          },
          {
            pattern: /^(\w){6,20}$/,
            message: "只能输入8-13个字母、数字、下划线",
          },
        ],
        code: [
          {
            required: true,
            message: "简码不能为空",
            trigger: "blur",
          },
        ],
      },
    };
  },
  watch: {
    show(e) {
      if (this.type === "add") {
        this.title = "新增代理";
        this.passwordPlaceholder = "不少于八位数";
        this.formData = {
          id: "",
          name: "",
          default_express_id: "",
          level_id: "",
          agent_cate_id: "",
          desc: "",
          sort: 1,
          password: "",
          code: "",
          can_handle_order: 1,
          shopnames: "",
        };
      } else {
        this.title = "编辑代理";
        this.passwordPlaceholder = "如不修改密码无需填写";
        this.formData = {
          id: this.item.id,
          name: this.item.name,
          default_express_id: this.item.default_express_id,
          level_id: this.item.level_id,
          agent_cate_id: this.item.agent_cate_id,
          desc: this.item.desc,
          sort: this.item.sort,
          password: "",
          code: this.item.code,
          contact_person: this.item.contact_person,
          contact_number: this.item.contact_number,
          inner_password: this.item.inner_password,
          can_handle_order: this.item.can_handle_order,
          shopnames: this.item.shopnames,
        };
      }
    },
  },
  methods: {
    //取消
    handleCancel() {
      this.hide = !this.hide;
    },
    //确定
    handleComfirm() {
      const fromData = this.formData;
      let data = {
        name: fromData.name,
        default_express_id: fromData.default_express_id,
        level_id: fromData.level_id,
        agent_cate_id: fromData.agent_cate_id,
        desc: fromData.desc,
        sort: fromData.sort,
        password: fromData.password,
        code: fromData.code,
        contact_person: fromData.contact_person,
        contact_number: fromData.contact_number,
        inner_password: fromData.inner_password,
        can_handle_order: fromData.can_handle_order,
      };
      if (this.type === "add") {
        this.handleAddComfirm(data);
        return;
      } else {
        data.id = fromData.id;
        if (fromData.password) {
          data.password = fromData.password;
        }
        this.handleEditComfirm(data);
      }
    },
    //新增提交
    async handleAddComfirm(data) {
      const res = await this.$store.dispatch("addAgent", data);
      this.$tool.comfirmToast(res).then(() => {
        this.$emit("succ");
        this.hide = !this.hide;
      });
    },
    //编辑提交
    async handleEditComfirm(data) {
      const res = await this.$store.dispatch("editAgent", data);
      this.$tool.comfirmToast(res).then(() => {
        this.$emit("succ");
        this.hide = !this.hide;
      });
    },
  },
};
</script>
<style lang="less" scoped>
.dialog-footer {
  display: flex;
  justify-content: flex-end;
}
</style>