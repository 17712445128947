<template>
  <el-row class="agent-nav" :gutter="20">
    <el-col :span="3">
      <el-select
        v-model="typeIds"
        multiple
        collapse-tags
        placeholder="代理类型"
      >
        <el-option
          v-for="item in typeList"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
    </el-col>
    <el-col :span="3">
      <el-select
        v-model="levelIds"
        multiple
        collapse-tags
        placeholder="代理级别"
      >
        <el-option
          v-for="item in levelList"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
    </el-col>
    <el-col :span="2">
      <el-button type="primary" @click="handleFilterClick">筛选</el-button>
    </el-col>
    <el-col :span="4">
      <el-input
        placeholder="请输入代理名称关键字"
        v-model="searchVal"
        class="input-with-select"
      >
        <el-button
          slot="append"
          icon="el-icon-search"
          @click="handleSearch"
        ></el-button>
      </el-input>
    </el-col>
    <el-col :span="3">
      <el-button type="primary" @click="handleAddItem" icon="el-icon-plus"
        >新增代理</el-button
      >
    </el-col>
  </el-row>
</template>
<script>
export default {
  name: "Nav",
  props: {
    typeList: Array,
    levelList: Array,
  },
  data() {
    return {
      levelIds: Array,
      typeIds: Array,
      searchVal: "",
    };
  },
  methods: {
    //筛选
    handleFilterClick() {
      let level = "";
      let type = "";
      this.levelIds.forEach((item) => {
        level += `${item},`;
      });
      this.typeIds.forEach((item) => {
        type += `${item},`;
      });
      this.$emit("filter", { level, type });
    },
    //新增
    handleAddItem() {
      this.$emit("add");
    },
    //搜索
    handleSearch() {
      this.$emit("search", this.searchVal);
    },
  },
};
</script>
<style lang="less" scoped>
.agent-nav {
  display: flex;
  align-items: center;
}
</style>