<template>
  <ky-dialog title="代理产品" :show="show" :hide="hide" width="50%">
    <list slot="dialog-content" :list="list" @edit="handleEditPrice"></list>
  </ky-dialog>
</template>
<script>
import List from "./components/List";
export default {
  name: "AgentProd",
  components: { List },
  props: {
    show: Boolean,
    id: [String, Number],
  },
  data() {
    return {
      hide: false,
      labelPosition: "right",
      list: [],
      priceShow: false,
    };
  },
  watch: {
    show(e) {
      this.getAgentFindProdInfo();
    },
  },
  created() {
    this.$bus.$on("AgentProdPriceEditSucc", () => {
      this.getAgentFindProdInfo();
    });
  },
  methods: {
    //获取代理所属产品信息
    async getAgentFindProdInfo() {
      const res = await this.$store.dispatch("getAgentFindProdInfo", {
        agent_id: this.id,
      });
      this.list = res.items;
    },
    //编辑代理价格
    handleEditPrice(prodId) {
      this.$emit("price", prodId);
    },
  },
};
</script>
<style lang="less" scoped>
.dialog-footer {
  display: flex;
  justify-content: flex-end;
}
</style>