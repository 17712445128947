<template>
  <ky-dialog title="编辑代理产品价格" :show="show" :hide="hide">
    <el-input
      slot="dialog-content"
      v-model="price"
      placeholder="输入代理产品价格"
    ></el-input>
    <span slot="dialog-footer" class="dialog-footer">
      <el-button @click="handleCancel">取 消</el-button>
      <el-button type="primary" @click="handleComfirm">确 定</el-button>
    </span>
  </ky-dialog>
</template>
<script>
export default {
  name: "EditProdPrice",
  props: {
    show: Boolean,
    prodId: [String, Number],
    agentId: [String, Number],
  },
  data() {
    return {
      hide: false,
      labelPosition: "right",
      price: null,
    };
  },

  methods: {
    //取消
    handleCancel() {
      this.hide = !this.hide;
    },
    //确定
    async handleComfirm() {
      if (this.price <= 0) {
        this.$message({
          message: "金额无效",
          type: "error",
        });
        return;
      }
      const res = await this.$store.dispatch("editAgentProdPrice", {
        agent_id: this.agentId,
        goods_id: this.prodId,
        price: this.price,
      });
      this.$tool.comfirmToast(res).then(() => {
        this.hide = !this.hide;
        this.$bus.$emit("AgentProdPriceEditSucc");
      });
    },
  },
};
</script>
<style lang="less" scoped>
.dialog-footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
</style>