<template>
  <ky-dialog title="代理快递" :show="show" :hide="hide" width="50%">
    <list slot="dialog-content" :list="list" @edit="handleEditPrice"></list>

    <!-- <el-dialog
      width="30%"
      title="编辑代理价格"
      :visible.sync="innerVisible1"
      append-to-body
    >
      <el-input v-model="agentPrice1" placeholder="输入代理价格"></el-input>
      <el-button
        type="success"
        style="margin-top: 20px"
        @click="sureChangePrice1"
        >确定</el-button
      >
      <el-button
        type="success"
        style="margin-top: 20px"
        @click="cancelChangePrice1"
        >取消</el-button
      >
    </el-dialog> -->
  </ky-dialog>
</template>
<script>
import List from "./components/List";
export default {
  name: "AgentExpress",
  components: { List },
  props: {
    type: String,
    show: Boolean,
    id: [String, Number],
  },
  data() {
    return {
      hide: false,
      labelPosition: "right",
      list: [],
    };
  },
  watch: {
    show(e) {
      this.getAgentFindExpressInfo();
    },
  },
  created() {
    this.$bus.$on("AgentExpressPriceEditSucc", () => {
      this.getAgentFindExpressInfo();
    });
  },
  methods: {
    //获取代理所属产品信息
    async getAgentFindExpressInfo() {
      const res = await this.$store.dispatch("getAgentFindExpressInfo", {
        agent_id: this.id,
      });
      this.list = res.items;
    },
    //编辑代理价格
    handleEditPrice(expressId) {
      this.$emit("price", expressId);
    },
  },
};
</script>
<style lang="less" scoped>
.dialog-footer {
  display: flex;
  justify-content: flex-end;
}
</style>