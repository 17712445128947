<template>
  <div class="wrapper">
    <agent-nav
      :typeList="typeList"
      :levelList="levelList"
      @add="handleAddItem"
      @filter="handleFilterList"
      @search="handleSearch"
    ></agent-nav>
    <list
      :list="agentList"
      @prod="handleProdCheck"
      @express="handleExpressCheck"
      @edit="handleEditItem"
      @recharge="handleRechargeItem"
      @succ="handleSucc"
    ></list>
    <pagination
      :page="page"
      :pageSize="pageSize"
      :total="total"
      @sizeChange="handlePageSizeChange"
      @pageChange="handlePageChange"
    >
    </pagination>

    <agent-prod
      :show="prodShow"
      :id="agentItem.id"
      @price="handleEditProdPrice"
    ></agent-prod>

    <agent-express
      :show="expressShow"
      :id="agentItem.id"
      @price="handleEditExpressPrice"
    ></agent-express>
    <edit-agent
      :show="agentItemShow"
      :type="agentItemType"
      :expressList="expressList"
      :levelList="levelList"
      :typeList="typeList"
      :item="agentItem"
      @succ="handleSucc"
    ></edit-agent>
    <agent-recharge
      :show="rechargeShow"
      :id="agentItem.id"
      @succ="handleSucc"
    ></agent-recharge>

    <edit-prod-price
      :show="prodPriceShow"
      :agentId="agentItem.id"
      :prodId="prodId"
    ></edit-prod-price>

    <edit-express-price
      :show="expressPriceShow"
      :agentId="agentItem.id"
      :expressId="expressId"
    ></edit-express-price>
  </div>
</template>

<script>
import AgentNav from "./components/Nav";
import List from "./components/List";
import EditAgent from "./components/EditAgent";
import AgentRecharge from "./components/AgentRecharge";
import AgentProd from "./components/AgentProd";
import EditProdPrice from "./components/EditProdPrice";
import AgentExpress from "./components/AgentExpress";
import EditExpressPrice from "./components/EditExpressPrice";
export default {
  name: "AgentManage",
  components: {
    AgentNav,
    List,
    EditAgent,
    AgentRecharge,
    AgentProd,
    EditProdPrice,
    AgentExpress,
    EditExpressPrice,
  },
  props: {},

  data() {
    return {
      agentList: [],
      expressId: null,
      expressPriceShow: false,
      expressShow: false,
      prodId: null,
      prodPriceShow: false,
      prodShow: false,
      rechargeShow: false,
      agentItemShow: false,
      agentItemType: "add",
      agentItem: {},
      agent_level_id: "",
      agent_type_id: "",
      pageSize: 20,
      page: 1,
      total: 0,
      expressList: [],
      typeList: [],
      levelList: [], //
      allAgent: [],
    };
  },
  created() {
    this.getAgentList();
    this.getAgentTypeList();
    this.getAgentLevelList();
    this.getAllExpress();
  },
  methods: {
    //获取代理列表
    async getAgentList() {
      const res = await this.$store.dispatch("getAgentList", {
        page: this.page,
        pageSize: this.pageSize,
        agent_level_id: this.agent_level_id,
        agent_type_id: this.agent_type_id,
      });
      this.agentList = res.items;
      this.allAgent = this.agentList;
      this.total = res.total;
    },
    //根据筛选条件获取代理列表
    handleFilterList(param) {
      this.agent_level_id = param.level;
      this.agent_type_id = param.type;
      this.getAgentList();
    },
    //搜索
    handleSearch(val) {
      this.search_text = val;
      if (val === "") {
        this.agentList = this.allAgent;
        return;
      }
      this.agentList = this.allAgent.reduce((prev, cur) => {
        if (cur.name.indexOf(val) > -1) {
          prev.push(cur);
        }
        return prev;
      }, []);
    },
    //获取代理类型列表
    async getAgentTypeList() {
      const res = await this.$store.dispatch("getAgentTypeList");
      this.typeList = res.items;
    },
    //获取代理等级列表
    async getAgentLevelList() {
      const res = await this.$store.dispatch("getAgentLevelList");
      this.levelList = res.items;
    },
    //获取统计
    async agentStatistic() {
      const data = {
        agent_type_id: this.agent_type_id,
        agent_level_id: this.agent_level_id,
        search_text: this.search_text,
        startTime: this.startTime,
        endTime: this.endTime,
      };
      const res = await this.$store.dispatch("agentStatistic", data);
    },
    //获取所有快递
    async getAllExpress() {
      const res = await this.$store.dispatch("getAllExpress");
      this.expressList = res.items;
    },
    //点击弹出新增弹窗
    handleAddItem() {
      this.agentItemShow = !this.agentItemShow;
      this.agentItemType = "add";
    },
    //点击弹出编辑弹窗
    handleEditItem(item) {
      this.agentItem = item;
      this.agentItemShow = !this.agentItemShow;
      this.agentItemType = "edit";
    },
    //弹出充值弹窗
    handleRechargeItem(item) {
      this.agentItem = item;
      this.rechargeShow = !this.rechargeShow;
    },
    //弹出代理所属产品
    handleProdCheck(item) {
      this.agentItem = item;
      this.prodShow = !this.prodShow;
    },
    //弹出编辑代理产品价格
    handleEditProdPrice(id) {
      this.prodId = id;
      this.prodPriceShow = !this.prodPriceShow;
    },
    //弹出代理所属快递
    handleExpressCheck(item) {
      this.agentItem = item;
      this.expressShow = !this.expressShow;
    },
    //弹出编辑代理快递价格
    handleEditExpressPrice(id) {
      this.expressId = id;
      this.expressPriceShow = !this.expressPriceShow;
    },
    //提交成功
    handleSucc() {
      this.getAgentList();
    },
    //切换页面展示数量
    handlePageSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.getAgentList();
    },
    //切换页数
    handlePageChange(page) {
      this.page = page;
      this.getAgentList();
    },
  },
};
</script>
<style lang="less" scoped>
.wrapper {
  /deep/.el-pagination {
    text-align: left;
  }
}
</style>
