<template>
  <table-container :list="list">
    <el-table-column type="index" width="50" label="序号"> </el-table-column>
    <el-table-column property="title" label="产品名称"></el-table-column>
    <el-table-column property="model_name" label="模型"></el-table-column>
    <el-table-column property="agent_price" label="代理价"> </el-table-column>
    <el-table-column property="market_price" label="市场价"></el-table-column>
    <el-table-column label="操作">
      <template slot-scope="scope">
        <el-button
          type="text"
          size="mini"
          icon="el-icon-edit"
          @click="handleEdit(scope.row.id)"
          >编辑代理价</el-button
        >
      </template>
    </el-table-column>
  </table-container>
</template>
<script>
export default {
  name: "List",
  props: {
    list: Array,
  },
  methods: {
    handleEdit(prodId) {
      this.$emit("edit", prodId);
    },
  },
};
</script>
<style lang="less" scoped>
</style>